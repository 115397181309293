import React from 'react';
import '../assets/css/Totd.css'; // Import the CSS file for styling
import DutchExampleImage from '../assets/images/DutchExample.png'; // Import the image

const Dutch = () => {
  return (
    <div className="container">
      <section className="ctext section-body" id="header1-0" data-rv-view="0">
        <div className="filter-container">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 lead">
                <script type="text/javascript" src="https://js.beteasyaffiliates.com.au/javascript.php?prefix=So3i3fd7q6T45mrAMZ2h2WNd7ZgqdRLk&amp;media=710&amp;campaign=1"></script>
              </div>
            </div>
          
            <div className="row">
              <div className="totdText">
                <h3>What is Dutch Betting?</h3>
                <p>Dutch betting, or Dutching is the practice of backing more than one selection to achieve the same profit regardless of which selection wins. Our Dutch betting calculator determines how much of the total bet amount to stake on each selection in order to return the same profit (as long as one of them wins).</p>
                <p>In the following example the $100 outlay is split across four horses, with a profit of $62 if one of them wins</p>
                <div className="navbar-logo">
                  <img src={DutchExampleImage} height="80%" width="80%" alt="Dutch Example" />
                </div>
                <p><a className="nav-link" href="http://tools.rippaday.com">Try it now</a></p>
                <script type="text/javascript" src="https://js.beteasyaffiliates.com.au/javascript.php?prefix=So3i3fd7q6T45mrAMZ2h2WNd7ZgqdRLk&amp;media=710&amp;campaign=1"></script>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dutch;
